<template>
  <qmp-layout title="设置">
    <ul class="block">
      <li
        v-for="(item, index) in items"
        :class="{ item: true, 'margin-right': index % 2 === 1 }"
        :key="item.type"
        @click="onSelect(item)"
      >
        <span :class="`iconfont icon ${item.icon}`"></span>
        <h5 class="name">{{ item.name }}</h5>
        <p v-if="item.des" class="des">{{ item.des }}</p>
      </li>
    </ul>
  </qmp-layout>
</template>

<script>
import { systemInit } from "../../../utils/global";

export default {
  name: "SettingIndex",

  data() {
    return {
      // 菜单列表数据
      items: [
        {
          type: "updateTicket",
          icon: "icon-piao",
          name: "修改票纸数量"
        },
        {
          type: "updateAppearance",
          icon: "icon-xinghao",
          name: "修改外设型号"
        },
        {
          type: "clear",
          icon: "icon-qingkong",
          name: "清空应用缓存"
        },
        {
          type: "switchScenic",
          icon: "icon-qiehuan111",
          name: "切换景区",
          des: `当前选择：${this.$store.state.common?.saleMerchantName || "无"}`
        },
        {
          type: "exchangeNewOld",
          icon: "icon-banben",
          name: localStorage.newVersion ? "切换旧版" : "切换新版"
        }
      ]
    };
  },

  methods: {
    // 菜单选择
    onSelect(item) {
      switch (item.type) {
        case "updateTicket":
          // 更新一遍 纸张数量
          this.$router.push(`/settingForm?type=${item.type}`);
          break;
        case "updateAppearance":
          this.$router.push(`/settingForm?type=${item.type}`);
          break;
        case "clear":
          localStorage.clear();
          this.$message.success("清理缓存成功");
          systemInit(this);
          break;
        case "switchScenic":
          this.$router.push(`/switchScenic`);
          break;
        case "exchangeNewOld":
          localStorage.setItem("newVersion", "false");
          location.reload();
          break;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.block {
  padding: 42px 50px;
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: calc(50% - 24px);
  height: 300px;
  margin-right: 24px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 14px;
  background: #ffffff;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);

  &.margin-right {
    margin-left: 24px;
    margin-right: 0;
  }

  &:active {
    background-color: #999999;
  }

  .icon {
    display: inline-block;
    width: 104px;
    height: 92px;
    margin-top: 74px;
    line-height: 92px;
    font-size: 104px;
    color: #28c58a;
  }

  .name {
    margin-top: 16px;
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #000000;
  }

  .des {
    margin-top: 8px;
    font-size: 22px;
    font-weight: normal;
    letter-spacing: 0em;
    color: #28c58a;
  }
}
</style>
