<template>
  <div class="setting-forms">
    <div class="setting-forms__header">{{ currentForm.title }}</div>
    <div class="setting-forms__body">
      <a-form-model
        ref="ruleForm"
        v-bind="layout"
        :model="currentForm.ruleForm"
        :rules="currentForm.rules"
      >
        <slot name="content"></slot>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-button type="primary" @click="submitForm('ruleForm')">
            保存
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    currentForm: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 12 }
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("on-save");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../../../assets/less/custom.less";
.setting-forms {
  margin: 20px;
  padding: 15px 10px;
  box-sizing: border-box;
  border: 1px solid @border;
  background-color: #fff;
  border-radius: 10px;
  &__header {
    padding-bottom: 15px;
    font-size: 32px;
    border-bottom: 1px solid @border;
  }
  &__body {
    /deep/ .ant-form-item {
      margin-top: 20px;
      display: flex;
      .setting-form__text {
        font-size: 24px;
        line-height: 50px;
      }
      .ant-input {
        height: 50px;
        font-size: 24px;
      }
      .ant-input-suffix {
        font-size: 24px;
      }
      .ant-form-explain {
        font-size: 24px;
      }
      .ant-btn {
        width: 200px;
        height: 50px;
        border-radius: 30px;
      }
    }
  }
}
</style>
