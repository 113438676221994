<template>
  <div class="setting-form hei">
    <!-- 返回菜单 -->
    <back :text="getCurrentForm.title"></back>
    <div class="slide">
      <setting-forms :current-form="getCurrentForm" @on-save="onSave">
        <!-- 修改票纸数量 -->
        <template v-if="$route.query.type === 'updateTicket'" slot="content">
          <a-form-model-item label="剩余门票打印纸">
            <div class="setting-form__text">
              {{ machineDetail.remainingVotes }}张
            </div>
          </a-form-model-item>

          <a-form-model-item label="修改后数量">
            <a-input
              v-model="getCurrentForm.ruleForm.ticketsInput"
              addon-after="张"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>

          <a-form-model-item label="剩余项目打印纸">
            <div class="setting-form__text">
              {{ machineDetail.remainingItemVotes }}张
            </div>
          </a-form-model-item>

          <a-form-model-item label="修改后数量">
            <a-input
              v-model="getCurrentForm.ruleForm.projectInput"
              addon-after="张"
              onkeyup="this.value = this.value.replace(/[^\d]/g,'')"
            />
          </a-form-model-item>
        </template>
        <!-- 修改外设型号 -->
        <template v-else slot="content">
          <!-- 门票打印模板 -->
          <a-form-model-item label="门票打印模板">
            <a-select
              placeholder="请选择门票打印模板"
              v-model="getCurrentForm.ruleForm.machinePrintTemplate"
            >
              <a-select-option
                :value="son.id"
                v-for="(son, index) in ticketTemplate"
                :key="index"
              >
                {{ son.templateName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 项目套餐打印模板 -->
          <a-form-model-item
            label="项目套餐打印模板"
            prop="projectPrintTemplate"
            :rules="[
              {
                required: true,
                message: '请选择',
                trigger: 'change'
              }
            ]"
          >
            <a-select
              placeholder="请选择项目套餐打印模板"
              v-model="getCurrentForm.ruleForm.projectPrintTemplate"
            >
              <a-select-option
                :value="son.id"
                v-for="(son, index) in projectTemplate"
                :key="index"
              >
                {{ son.templateName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- 其他硬件设置 -->
          <a-form-model-item
            :label="item.labelName"
            v-for="item in getCurrentForm.device"
            :key="item.key"
            :prop="item.key"
            :rules="[
              {
                required: item.items.length > 0,
                message: '请选择',
                trigger: 'change'
              }
            ]"
          >
            <a-select
              placeholder="请选择"
              v-model="getCurrentForm.ruleForm[item.key]"
            >
              <a-select-option
                :value="son"
                v-for="(son, index) in item.items"
                :key="index"
              >
                {{ son }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </template>
      </setting-forms>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import SettingForms from "./components/SettingForms/SettingForms.vue";
import { updateMachineVotes } from "@/api/iot";
import { getPrintTemplateList } from "@/api/setting";
export default {
  name: "settingForm",
  components: {
    Back: () => import("@/components/common/back"),
    SettingForms
  },
  data() {
    const formMap = {
      updateTicket: {
        title: "修改票纸数量",
        ruleForm: {
          ticketsInput: "",
          projectInput: ""
        },
        rules: {
          // ticketsInput: [
          //   { required: true, message: "请输入门票张数", trigger: "blur" }
          // ],
          // projectInput: [
          //   { required: true, message: "请输入项目打印纸张数", trigger: "blur" }
          // ]
        }
      },
      updateAppearance: {
        title: "修改外设型号",
        device: [
          // { labelName: "门票打印模板", key: "machinePrintTemplate", items: [] },
          // {
          //   labelName: "项目套餐打印模板",
          //   key: "projectPrintTemplate",
          //   items: []
          // }
        ],
        ruleForm: {
          machinePrintTemplate: "", // 门票打印模板
          projectPrintTemplate: "" // 项目套餐打印模板
        },
        rules: {}
      }
    };
    return {
      formMap,
      options: [],
      ticketTemplate: [], // 门票打印模板数据
      projectTemplate: [] // 项目套餐打印模板
    };
  },
  computed: {
    getCurrentForm() {
      const type = this.$route.query.type;
      return this.formMap[type];
    },
    ...mapGetters("setting", ["machineDetail"])
  },
  created() {
    this.getPrintTemplateList(1); // 获取门票打印模板
    this.getPrintTemplateList(5); // 获取项目套餐打印模板
    this.hardInit();
  },
  methods: {
    // 硬件设置数据
    hardInit() {
      // 获取硬件配置数据
      let me = this;
      let Hardware = localStorage.getItem("Hardware");
      if (window.test) {
        try {
          let opt = window.test.initDevice();
          if (opt) {
            // // const arr = JSON.parse(opt);
            // // 去掉交账打印机数据：后端接口不愿意处理
            // arr.forEach((item, index) => {
            //   if (item.key == "accountPrintNames") {
            //     arr.splice(index, 1);
            //   }
            // });
            // me.options = arr;

            // 去掉交账打印机数据：后端接口不愿意处理(有些打印机是窗口端项目需要，自助机不需要，后端全部一起返回来)
            /**
             * accountPrintNames:交账打印机
             * smallPrinterNames:收银小票打印机
             * consumeRecordPrinterNames:消费记录打印机;
             * callSmallPrintNames:叫号小票打印机;
             */
            const arr = [
              "accountPrintNames",
              "smallPrinterNames",
              "consumeRecordPrinterNames",
              "callSmallPrintNames"
            ];
            const arr2 = JSON.parse(opt);
            const newData = arr2.filter(item => {
              return !arr.includes(item.key);
            });
            me.options = newData;
          }
          this.formMap.updateAppearance.device = me.options;
          //表单渲染完成，如果有旧的缓存配置，把旧的缓存配置更新到这里
          if (Hardware) {
            me.formMap.updateAppearance.ruleForm = JSON.parse(Hardware);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    /**
     * @description 保存
     */
    onSave() {
      const type = this.$route.query.type;
      // 修改票纸数量
      if (type === "updateTicket") {
        if (
          !this.getCurrentForm.ruleForm.ticketsInput &&
          !this.getCurrentForm.ruleForm.projectInput
        ) {
          this.$message.warning("至少输入一项修改数量");
          return;
        }
        this.updateMachineVotes();
        this.setMachineDetail(
          Object.assign(this.machineDetail, {
            remainingVotes: this.getCurrentForm.ruleForm.ticketsInput
              ? this.getCurrentForm.ruleForm.ticketsInput
              : this.machineDetail.remainingVotes,
            remainingItemVotes: this.getCurrentForm.ruleForm.projectInput
              ? this.getCurrentForm.ruleForm.projectInput
              : this.machineDetail.remainingItemVotes
          })
        );
      } else if (type === "updateAppearance") {
        // 修改外设型号
        let setting = JSON.stringify(this.formMap.updateAppearance.ruleForm);
        console.log("修改外设型号传给后台的数据:", setting);
        try {
          window.test.choiceDevice(setting);
          localStorage.setItem("Hardware", setting);
          this.$message.success("保存成功");
          this.$router.replace("/settingMain");
        } catch (error) {
          console.log(error);
          this.$error("初始化失败");
        }
      }
    },

    /**
     * @description 批量获取打印模板
     */
    async getPrintTemplateList(type) {
      const res = await getPrintTemplateList({
        templateType: type // 1、门票打印模板类型，5项目套餐打印模板类型
      });
      if (type == 1) {
        res.unshift({
          id: "",
          templateName: "无"
        });
        this.ticketTemplate = res;
      } else {
        this.projectTemplate = res;
      }
    },

    /**
     * @@description 自助机剩余票数修改
     */
    async updateMachineVotes() {
      const getCurrentForm = this.getCurrentForm;
      await updateMachineVotes({
        machineSnCode: localStorage.getItem("SNCODE"),
        remainingVotes: getCurrentForm.ruleForm.ticketsInput
          ? getCurrentForm.ruleForm.ticketsInput
          : this.machineDetail.remainingVotes,
        remainingItemVotes: getCurrentForm.ruleForm.projectInput
          ? getCurrentForm.ruleForm.projectInput
          : this.machineDetail.remainingItemVotes
      });
    },
    ...mapMutations("setting", ["setMachineDetail"])
  }
};
</script>
<style lang="less" scoped>
.slide {
  max-height: 90%;
}
</style>
