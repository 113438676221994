<template>
  <QmpLayout title="切换景区">
    <ul class="wrap">
      <li
        v-for="item in lists"
        :key="item.subScenicId"
        :class="{
          item: true,
          active: item.subScenicId === activeItem.subScenicId
        }"
        @click="onSelect(item)"
      >
        {{ item.subScenicName }}
      </li>
    </ul>
    <div slot="footer" class="footer">
      <qmp-button type="primary" block @click="ok">确定</qmp-button>
    </div>
  </QmpLayout>
</template>

<script>
import { subScenics } from "@/api/merchant";

export default {
  name: "SwitchScenic",

  data() {
    return {
      lists: [], // 景区列表
      activeItem: {} // 选中景区ID
    };
  },

  computed: {
    merchantId() {
      return this.$store.state.common.saleMerchantId;
    }
  },

  created() {
    this.subScenics();
  },

  methods: {
    // 查询子景区
    subScenics() {
      subScenics()
        .then(({ subScenics }) => {
          this.lists = subScenics;
          // 激活选中
          const item = this.lists.find(
            temp => temp.subScenicId === this.merchantId
          );
          if (item) this.activeItem = item;
        })
        .catch(() => {
          this.$error({
            title: "提示",
            content: "未获取到子景区信息"
          });
        });
    },
    // 设置选中景区
    onSelect(item) {
      this.activeItem = item;
    },
    ok() {
      this.$store.commit("common/setSaleMerchant", {
        merchantId: this.activeItem.subScenicId,
        merchantName: this.activeItem.subScenicName
      });
      this.$router.go(-1);
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 35px 30px;
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: calc(50% - 19px);
  height: 150px;
  margin-bottom: 30px;
  margin-right: 19px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 14px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);
  font-size: 24px;
  color: #111111;
  background: #fff;

  &:nth-child(2n) {
    margin-left: 19px;
    margin-right: 0;
  }

  &.active {
    border-color: #28c58a;
    color: #28c58a;
  }
}

.footer {
  padding: 24px 185px;
  height: auto;
}
</style>
