<!--
  @name: SettingPrevious
  @author: zhengjiana
-->
<template>
  <div>
    <QmpKeyboard
      class="keyboard"
      input-type="password"
      placeholder="请输入设备管理密码"
      :is-show-x="false"
      v-model="operationPassword"
      @ok="ok"
    >
    </QmpKeyboard>
  </div>
</template>

<script>
import QmpKeyboard from "@/components/common/QmpKeyboard";
import { machineLogin } from "@/api/iot";

export default {
  name: "SettingPrevious",

  components: {
    QmpKeyboard
  },

  props: {},

  data() {
    return {
      operationPassword: ""
    };
  },

  methods: {
    // 键盘确定
    ok() {
      if (!this.operationPassword) {
        this.$warning("请先输入密码");
        return;
      }
      this.machineLogin();
    },
    async machineLogin() {
      const res = await machineLogin({
        machineSnCode: localStorage.getItem("SNCODE"),
        operationPassword: this.operationPassword
      });
      if (!res) {
        this.$warning("密码错误");
        return;
      }
      this.$message.success("登录成功");
      this.$router.push("/setting");
    }
  }
};
</script>

<style lang="less" scoped>
.keyboard {
  width: 660px;
  margin: 390px auto 0;
}
</style>
